



































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import "@wangeditor/editor/dist/css/style.css"; // 引入 css
import { UploadFile } from "@/request/common";
import { IDomEditor, IButtonMenu, Boot, IModuleConf } from "@wangeditor/editor";

class MyButtonMenu implements IButtonMenu {
  constructor(public title: string, public tag: string) {
    this.title = title; // 自定义菜单标题
    this.tag = tag;
  }

  // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
  getValue(editor: IDomEditor): string | boolean {
    // TS 语法
    // getValue(editor) {                              // JS 语法
    return "";
  }

  // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
  isActive(editor: IDomEditor): boolean {
    // TS 语法
    // isActive(editor) {                    // JS 语法
    return false;
  }

  // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
  isDisabled(editor: IDomEditor): boolean {
    // TS 语法
    // isDisabled(editor) {                     // JS 语法
    return false;
  }

  // 点击菜单时触发的函数
  exec(editor: IDomEditor, value: string | boolean) {
    // TS 语法
    // exec(editor, value) {                              // JS 语法
    if (this.isDisabled(editor)) return;
    // editor.insertText('4555'); // value 即 this.value(editor) 的返回值
    const fileInputs: any = document.querySelector(".upload-demo");
    fileInputs.click();
  }
}
const menu1Conf = {
  key: "uploadFile", // 定义 menu key ：要保证唯一、不重复（重要）
  factory() {
    return new MyButtonMenu("上传文件", "button"); // 把 `YourMenuClass` 替换为你菜单的 class
  },
};
Boot.registerMenu(menu1Conf as any);
@Component({
  components: {
    Editor,
    Toolbar,
  },
})
export default class Name extends Vue {
  private title = "";
  @Prop({
    type: Boolean,
    default: false,
  })
  private readOnly: any; // 是否是只读状态
  @Watch("readOnly", { immediate: true })
  private readOnlyChange() {
    const editor = this.editorData;
    if (this.readOnly) {
      editor.disable();
    } else {
      editor.enable();
    }
    setTimeout(() => {
      this.htmlText = this.data;
      this.countHeight();
    }, 100);
    // this.countHeight();
  }
  @Prop({
    type: String,
    default: "dsEditor",
  })
  private editorName: any;
  @Prop({
    type: String,
    default: "400",
  })
  private height: any; // 不自动计算高度时传入的编辑框高度
  @Prop({
    type: Boolean,
    default: false,
  })
  private ifHideScroll: any; // 编辑框是否出现滚动条,如果不出现，传入的高则不会生效，直接平铺
  @Prop({
    type: Boolean,
    default: false,
  })
  private autoHeight: any; // 是否自动计算高度,
  @Prop({
    type: String,
    default: "20",
  })
  private autoBottom: any; // 自动计算高度时距离页面底部距离,
  @Prop()
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    const editor = this.editorData;
    if (this.readOnly) {
      editor.disable();
    } else {
      editor.enable();
    }
    setTimeout(() => {
      this.htmlText = this.data;
      this.countHeight();
    }, 100);
    // this.htmlText = this.data;
    // this.countHeight();
  }
  private editorData: any = {};
  private htmlText: any = "";
  private toolbarConfig: any = {
    toolbarKeys: [
      "bold",
      "underline",
      "italic",
      "through",
      "code",
      "sub",
      "sup",
      "clearStyle",
      "color",
      "bgColor",
      "fontSize",
      "fontFamily",
      "indent",
      "delIndent",
      "justifyLeft",
      "justifyRight",
      "justifyCenter",
      "justifyJustify",
      "lineHeight",
      "insertImage",
      "deleteImage",
      "editImage",
      "viewImageLink",
      "imageWidth30",
      "imageWidth50",
      "imageWidth100",
      "divider",
      "emotion",
      "insertLink",
      "editLink",
      "unLink",
      "viewLink",
      "codeBlock",
      "blockquote",
      "headerSelect",
      "header1",
      "header2",
      "header3",
      "header4",
      "header5",
      "todo",
      "redo",
      "undo",
      // "fullScreen",
      "enter",
      "bulletedList",
      "numberedList",
      "insertTable",
      "deleteTable",
      "insertTableRow",
      "deleteTableRow",
      "insertTableCol",
      "deleteTableCol",
      "tableHeader",
      "tableFullWidth",
      "insertVideo",
      "uploadVideo",
      "editVideoSize",
      "uploadImage",
      "codeSelectLang",
      "uploadFile",
    ],
    // excludeKeys: ["fullScreen"],
    insertKeys: {
      index: 60, // 插入的位置，基于当前的 toolbarKeys
      keys: ["uploadFile"],
    },
  };

  private editorHeight: any = "";
  private editorConfig: any = {
    placeholder: "请输入内容...",
    MENU_CONF: {
      uploadImage: {
        allowedFileTypes: [],
        customUpload(file: any, insertFn: any) {
          const fileObj = file;
          // FormData 对象
          const form = new FormData();
          // 文件对象
          form.append("type", "file");
          form.append("file", fileObj);
          UploadFile(this, form).then((data: any) => {
            insertFn(data.full_file_url, "", data.full_file_url);
          });
        },
      },
      uploadVideo: {
        customUpload(file: any, insertFn: any) {
          const fileObj = file;
          // FormData 对象
          const form = new FormData();
          // 文件对象
          form.append("type", "file");
          form.append("file", fileObj);
          UploadFile(this, form).then((data: any) => {
            insertFn(data.full_file_url, "", data.full_file_url);
          });
        },
      },
      uploadFile: {
        customUpload(file: any, insertFn: any) {
          const fileObj = file;
          // FormData 对象
          const form = new FormData();
          // 文件对象
          form.append("type", "file");
          form.append("file", fileObj);
          UploadFile(this, form).then((data: any) => {
            insertFn(data.full_file_url, "", data.full_file_url);
          });
        },
      },
    },
  };
  private mode: any = "default"; // default or 'simple'

  private get resizeTime() {
    return this.$store.state.resizeTime;
  }
  @Watch("resizeTime")
  private resizeTimeChange() {
    if (this.resizeTime) {
      this.countHeight();
    }
  }
  private refresh() {
    setTimeout(() => {
      this.htmlText = this.data;
      this.countHeight();
    }, 100);
  }
  private onCreated(editor: any) {
    if (this.readOnly) {
      editor.disable();
    } else {
      editor.enable();
    }
    this.editorData = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    // 打印编辑器注册的所有菜单 key
    // console.log(editor.getAllMenuKeys());
  }
  private onChange(editor: any) {
    const html = editor.getHtml();
    // this.$emit("change", html);
    if (html == "<p><br></p>") {
      this.$emit("change", "");
    } else {
      this.$emit("change", html);
    }
  }
  private destroyed() {
    const editor = this.editorData;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  }
  // 计算编辑框高度
  private countHeight() {
    if (!this.autoHeight) {
      this.editorHeight = this.height;
    } else {
      const H: any =
        document.documentElement.clientHeight || document.body.clientHeight;
      let Top: any = 0;
      if (this.$refs[this.editorName]) {
        Top = (this.$refs[this.editorName] as any).getBoundingClientRect().top;
      }
      this.editorHeight = H - Top - this.autoBottom;
      // if (H - Top - this.autoBottom > 200) {
      //   this.editorHeight = H - Top - this.autoBottom;
      // } else {
      //   this.editorHeight = 200;
      // }
    }
  }
  private mounted() {
    setTimeout(() => {
      this.htmlText = this.data;
      this.countHeight();
    }, 100);
    // 模拟 ajax 请求，异步渲染编辑器
    setTimeout(() => {
      this.htmlText = this.data;
      this.countHeight();
    }, 100);
  }
  /**
   * @description 列表上传文档
   */
  private uploadFile(d: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const fileObj = d.file;
    // FormData 对象
    const form = new FormData();
    // 文件对象
    form.append("type", "file");
    form.append("file", fileObj);
    UploadFile(this, form)
      .then((data: any) => {
        loading.close();
        const obj = {
          name: d.file.name.split(".")[0],
          type: d.file.name.split(".")[1],
          url: data.full_file_url,
        };
        // 插入链接
        const node = {
          type: "paragraph",
          children: [
            {
              type: "link",
              url: data.full_file_url,
              children: [{ text: d.file.name }],
            },
          ],
        };
        this.editorData.insertNode(node as any);
      })
      .catch(() => {
        loading.close();
      });
  }
}
